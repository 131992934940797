// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oversigt-index-js": () => import("./../src/pages/oversigt/index.js" /* webpackChunkName: "component---src-pages-oversigt-index-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-test-url-js": () => import("./../src/pages/test-url.js" /* webpackChunkName: "component---src-pages-test-url-js" */),
  "component---src-templates-single-test-js": () => import("./../src/templates/single-test.js" /* webpackChunkName: "component---src-templates-single-test-js" */)
}

